<template>
  <el-container>
    <el-header height="210">
      <el-select
        @change="selectProvince"
        v-model="queryInfo.province"
        placeholder="请选择省份"
        style="margin-bottom: 25px; margin-left: 5px"
      >
        <el-option
          v-for="(item, index) in provinceData"
          :key="index"
          :label="item.label"
          :value="item.label"
        ></el-option>
      </el-select>
      <el-input
        v-model="queryInfo.score"
        @blur="getUserInfo"
        oninput="value=value.replace(/[^\d]/g,'')"
        placeholder="请输入分数"
        prefix-icon="el-icon-search"
        style="margin-left: 5px; width: 13%"
      ></el-input>
      <el-select
        @change="examChange"
        v-model="queryInfo.exam"
        placeholder="请选择题目"
        style="margin-bottom: 25px; margin-left: 5px"
      >
        <el-option
          v-for="(item, index) in allExamInfo"
          :key="index"
          :label="item.examName"
          :value="item.examId"
        >
        </el-option>
      </el-select>
      <el-button
        @click="exportExcelClick"
        :loading="excelBtn.excelBtnLoading"
        style="margin-left: 10px"
        type="primary"
      >
        {{ excelBtn.btnText }}
      </el-button>
    </el-header>
    <el-main>
      <el-table
        ref="questionTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="examInfo"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleTableSectionChange"
      >
        <el-table-column
          align="center"
          prop="province"
          label="省份"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="avgage"
          label="平均值"
        ></el-table-column>

        <el-table-column align="center" prop="totle" :label="fenshu">
          <template slot-scope="scope">
            <span class="detail" @click="gotoDetail(scope.row)">{{
              scope.row.totle == null ? "0" : scope.row.totle
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>
<script>
import { provinceData } from "../assets/json/area.js";
export default {
  data() {
    return {
      excelBtn: {
        excelBtnLoading: false,
        btnText: "导出当前页Excel",
      },
      loading: false,
      examInfo: [],
      //所有考试信息
      allExamInfo: [],
      provinceData: provinceData,
      fenshu: "",
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
        province: "全部省份",
        score: "60",
        exam: 0,
      },
    };
  },
  mounted() {
    this.getList();
    this.getAllExamInfo();
    if (this.provinceData[0].label !== "全部省份") {
      this.provinceData.unshift({ value: "", label: "全部省份" });
    }
  },
  methods: {
    getAllExamInfo() {
      this.$http.get(this.API.allExamInfo).then((resp) => {
        if (resp.data.code === 200) {
          this.allExamInfo = resp.data.data;
          console.log(this.allExamInfo);
        }
      });
    },
    //跳转详情
    gotoDetail(params) {
      this.$router.push({
        path: "/staticToatalavgxq",
        query: {
          region: params.province,
          score: this.queryInfo.score,
          examId: this.queryInfo.exam,
        },
      });
      console.log(this.queryInfo);
    },
    //导出excel
    exportExcelClick() {
      if (this.examInfo.length == 0) {
        this.$message.error("暂无考试记录！");
        return;
      }
      this.excelBtn.excelBtnLoading = true;
      this.excelBtn.btnText = "正在导出···";
      let gradeExcelList = [];
      console.log(this.examInfo);
      this.examInfo.forEach((item) => {
        let gradeExcelItem = {
          province: item.province,
          avgage: item.avgage,
          totle: item.totle == null ? "0" : item.totle,
        };
        gradeExcelList.push(gradeExcelItem);
      });

      this.$http({
        url: this.API.exitTotleAvg,
        method: "post",
        responseType: "blob",
        data: gradeExcelList,
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "统计平均.xls");
          document.body.appendChild(link);
          link.click();
          this.excelBtn.excelBtnLoading = false;
          this.excelBtn.btnText = "导出Excel";
        })
        .catch((err) => {
          this.excelBtn.excelBtnLoading = false;
          this.excelBtn.btnText = "导出Excel";
        });
    },
    examChange() {
      this.getList();
    },
    getList() {
      this.fenshu = "小于" + this.queryInfo.score + "分人数";
      this.$http
        .get(this.API.getTotleAvg, { params: this.queryInfo })
        .then((res) => {
          console.log(res);
          this.examInfo = res.data.data;
        });
    },
    //处理表格被选中
    handleTableSectionChange(val) {},
    selectProvince(provce) {
      //选择省份
      if (provce == "全部省份") {
        this.queryInfo.province = "";
      }
      this.getList();
    },
    getUserInfo(value) {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
/*表格的头部样式*/
/deep/ .has-gutter tr th {
  background: rgb(242, 243, 244);
  color: rgb(85, 85, 85);
  font-weight: bold;
  line-height: 32px;
}
.detail {
  color: #409eff;
  cursor: pointer;
}
</style>